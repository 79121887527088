import '../App.css'

export const Home = () => {

  return (
    <section className="home">
        <div className="home-container">
            <header>I help bring digital stores to <span>life</span></header>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" d="m6.523 12.5l3.735 3.735q.146.146.153.344q.006.198-.153.363q-.166.166-.357.169q-.191.002-.357-.163l-4.382-4.383q-.243-.242-.243-.565q0-.323.243-.565l4.382-4.383q.146-.146.347-.153q.201-.007.367.159q.16.165.162.354q.003.188-.162.353L6.523 11.5h12.38q.214 0 .358.143q.143.144.143.357t-.143.357q-.144.143-.357.143z"/></svg>
        </div>
    </section>
  )
}

