import '../App.css'
import garry from '../assets/garry.png'
import goose from '../assets/goose.webp'
import mickel from '../assets/mickel.webp'
import shadow from '../assets/shadow.webp'

export const Testimonials = () => {

  return (
    <section className="testimonials">
      <header>Testimonials</header>
        <div className="testimonials-container">

        <div className='testimonial-col'>
          <div className='testimonial'>
              <div className='user'>
                <img src={goose} className='user-icon' />
                <div className='user-info'>
                <h1>Goose <span> -   gooselore <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg></span></h1>
                <h4>Owner of Hoodcheats</h4>
                </div>
              </div>
              <div className='review'>
                <h4>Absolutely amazing person to work with, he kept me updated the entire time and did everything I asked him to. I highly reccomend!</h4>
              </div>
            </div>
            <div className='testimonial'>
              <div className='user'>
                <img src={mickel} className='user-icon' />
                <div className='user-info'>
                <h1>Mickel <span> -   mickelshop <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg></span></h1>
                <h4>Owner of Mickelshop & Official Quantum </h4>
                </div>
              </div>
              <div className='review'>
                <h4>Bobby does amazing work, got him reccomended by a friend and had reccomended him to a few of mine. He delivers just amazing work, plus he helps you with changes even after completing, great guy!</h4>
              </div>
            </div>
        </div>

        <div className='testimonial-col'>
          <div className='testimonial'>
              <div className='user'>
                <img src={shadow} className='user-icon' />
                <div className='user-info'>
                <h1>Shadower <span> -   shadower0001 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg></span></h1>
                <h4>Owner of Fivestarsolutions</h4>
                </div>
              </div>
              <div className='review'>
                <h4>Great website designer does everything in a timely matter. Help doesnt end after he has made the website and he send video guides etc on how to change anything on my website. would advice 10/10</h4>
              </div>
            </div>
        </div>
            
        <div className='testimonial-col'>
        <div className='testimonial'>
              <div className='user'>
                <img src={garry} className='user-icon' />
                <div className='user-info'>
                <h1>Garry <span> -   big.steppa.garry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg></span></h1>
                <h4>Owner of DLLcheats</h4>
                </div>
              </div>
              <div className='review'>
                <h4>Does great work for the price, extremely helpful, and just a very talented developer. 100/10</h4>
              </div>
            </div>
        </div>


        </div>
    </section>
  )
}

