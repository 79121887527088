import '../App.css'
import { useState, useEffect } from 'react';
import port from '../assets/port.PNG'
import mickel from '../assets/mickelshopPIC.PNG'
import goose from '../assets/hoodcheatsPIC.PNG'
import dllcheats from '../assets/dllcheatspic.PNG'

import sellapp from '../assets/sellapppic.jpg'
import sellix from '../assets/sellixpic.webp'
import stripe from '../assets/stripe1.jpg'
import sellpass from '../assets/sellpasspic.PNG'

export const Faq = () => {
    const [active, setActive] = useState(0);
    const [animateActiveIndex, setAnimateActiveIndex] = useState(0);


    useEffect(() => {
        let intervalId;

        const startLoop = (maxIndex) => {
        intervalId = setInterval(() => {
            setAnimateActiveIndex(prevIndex => (prevIndex + 1) % maxIndex);

        }, 4000);
        };


        const stopLoop = () => {
        clearInterval(intervalId);
        };

        if (active === 0) {
            startLoop(4);
        } else if (active === 1) {  
            startLoop(4);
        }else {
            stopLoop();
        }

        return () => clearInterval(intervalId);
    }, [active]);



    const activateIndex = (index) => {
        if(index === active) {
            return
        } else {
            setActive(index);
            setAnimateActiveIndex(0);
        }

      }
    
    
      const checkIfActive = (activeIndex, index) => {
        console.log(animateActiveIndex);
        return animateActiveIndex === index && active === activeIndex;
      }


  return (
    <section className="faq">
        <header>FAQ</header>
        <div className='faq-container'>
            <div className='faq-boxes'>

                <div onClick={() => activateIndex(0)} className={active === 0 ? 'faq-box-open' : 'faq-box-closed'}>
                    <div className='faq-question'>
                        <p>What services do you offer?</p>
                        <div className='faq-icon'>
                            <div className='faq-minus'></div>
                            <div className='faq-plus'></div>
                        </div>
                    </div>
                    <div className='faq-answer'>
                        <h4>I develop and design websites, but I specify in digital ecommerce websites. I help to transform generic ecommerce websites into beautiful modern websites. If you have a custom request, please message me the details.</h4>
                    </div>
                </div>

                <div onClick={() => activateIndex(1)}  className={active === 1 ? 'faq-box-open' : 'faq-box-closed'}>
                    <div className='faq-question'>
                        <p>What type of stores do you accept?</p>
                        <div className='faq-icon'>
                            <div className='faq-minus'></div>
                            <div className='faq-plus'></div>
                        </div>
                    </div>
                    <div className='faq-answer'>
                        <h4>I currently accept websites that sell digital products using platforms such as: Sellix, Sellpass, Sellapp, and Stripe. These platforms allow clients to easily edit and update their products and have easy-to-implement secure payment processers.</h4>
                    </div>
                </div>

                <div onClick={() => activateIndex(2)}  className={active === 2 ? 'faq-box-open' : 'faq-box-closed'}>
                    <div className='faq-question'>
                        <p>What is difference Basic and Advanced API Integration?</p>
                        <div className='faq-icon'>
                            <div className='faq-minus'></div>
                            <div className='faq-plus'></div>
                        </div>
                    </div>
                    <div className='faq-answer'>
                        <h4>The Basic API integration only updates the products when you make a change to a product. Advanced API integration allows for an integrated payment system which enables the purchase of mutliple items aka cart checkout. It also includes webhooks, which has a ton of possibliies like setting a discord bot that can send a message whenever a purchase was made. </h4>
                    </div>
                </div>

                <div onClick={() => activateIndex(3)} className={active === 3 ? 'faq-box-open' : 'faq-box-closed'}>
                    <div className='faq-question'>
                        <p>How do I purchase your services?</p>
                        <div className='faq-icon'>
                            <div className='faq-minus'></div>
                            <div className='faq-plus'></div>
                        </div>
                    </div>
                    <div className='faq-answer'>
                        <h4>If your interested in a website or want a custom request, message me on Discord: <span>bobbysmurf33</span></h4>
                    </div>
                </div>

                <div onClick={() => activateIndex(4)} className={active === 4 ? 'faq-box-open' : 'faq-box-closed'}>
                    <div className='faq-question'>
                        <p>What types of payments do you accept?</p>
                        <div className='faq-icon'>
                            <div className='faq-minus'></div>
                            <div className='faq-plus'></div>
                        </div>
                    </div>
                    <div className='faq-answer'>
                        <h4>I accept Paypal, Cashapp, and bank transfers (USA ONLY). I also accept certain crypto currencies with a 10% added fee: Bitcoin, Litecoin, Etherium, and USDT. There is a high unlikelihood I will accept any other types of payment.</h4>
                    </div>
                </div>

            </div>

            <div className='faq-images'>
                <div className='image-group'>
                    <img className={checkIfActive(0, 0) ? 'active-image' : ''} src={goose} />
                    <img className={checkIfActive(0, 1) ? 'active-image' : ''} src={mickel} />
                    <img className={checkIfActive(0, 2) ? 'active-image' : ''} src={port} />
                    <img className={checkIfActive(0, 3) ? 'active-image' : ''} src={dllcheats} />

                    <img className={checkIfActive(1, 0) ? 'active-image' : ''} src={sellapp} />
                    <img className={checkIfActive(1, 1) ? 'active-image' : ''} src={sellix} />
                    <img className={checkIfActive(1, 2) ? 'active-image' : ''} src={stripe} />
                    <img className={checkIfActive(1, 3) ? 'active-image' : ''} src={sellpass} />

                </div>
            </div>
        </div>
    </section>
  )
}

