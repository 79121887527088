import '../App.css'
import {Website} from '../components/website'
import test from '../assets/port.PNG'
import test2 from '../assets/port2.PNG'
import test3 from '../assets/port3.PNG'
import test4 from '../assets/port4.PNG'


import hood from '../assets/hoodcheatsPIC.PNG'
import hood2 from '../assets/hoodcheatsPIC2.PNG'
import hood3 from '../assets/hoodcheatsPIC3.PNG'
import hood4 from '../assets/hoodcheatsPIC4.PNG'


import mick from '../assets/mickelshopPIC.PNG'
import mick2 from '../assets/mickelshopPIC2.PNG'
import mick3 from '../assets/mickelshopPIC3.PNG'
import mick4 from '../assets/mickelshopPIC4.PNG'

import dll from '../assets/dllcheatspic.PNG'
import dll2 from '../assets/dllcheatspic2.PNG'
import dll3 from '../assets/dllcheatspic3.PNG'


const fivestarImages = [test, test2, test3, test4]
const mickImages = [mick, mick2, mick3, mick4]
const hoodImages = [hood, hood2, hood3, hood4]
const dllImages = [dll, dll2, dll3]

export const Projects = () => {

  return (
    <section className="projects">
        <header>Past Projects</header>
        <div className="projects-slider">
            <div className='past-project'>
                <p className='domain'>fivestarsolutions.cc <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17.6 18L8 8.4V17H6V5h12v2H9.4l9.6 9.6z"/></svg></p>
                <Website images={fivestarImages} packageType={2}/>
            </div>

            <div className='past-project'>
                <p className='domain-offline'>hoodcheats.com</p>
                <Website images={hoodImages} packageType={3}/>
            </div>

            <div className='past-project'>
                <p className='domain'>official-quantum.com<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17.6 18L8 8.4V17H6V5h12v2H9.4l9.6 9.6z"/></svg></p>
                <Website images={fivestarImages} packageType={2}/>
            </div>

            <div className='past-project'>
                <p className='domain'>dllcheats.com<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17.6 18L8 8.4V17H6V5h12v2H9.4l9.6 9.6z"/></svg></p>
                <Website images={dllImages} packageType={1}/>
            </div>

            <div className='past-project'>
                <p className='domain'>mickelshop.com<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17.6 18L8 8.4V17H6V5h12v2H9.4l9.6 9.6z"/></svg></p>
                <Website images={mickImages} packageType={2}/>
            </div>
            
            <div className='future-project'>
                <p>Your website here!</p>
                <div className='future-website'>
                    <div className='future-plus'>
                    </div>
                </div>
            </div>




        </div>
    </section>
  )
}

