import './App.css'
import { useState, useEffect } from 'react';
import {Navbar} from './components/navbar'
import {Home} from './slides/home'
import {Faq} from './slides/faq'
import {Projects} from './slides/projects'
import {Testimonials} from './slides/testimonials'
import {Prices} from './slides/prices'
import {ParticlesComponent} from './components/particles'

function App() {

  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 75) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }

    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ParticlesComponent id="particles" />
      <Navbar isfixedNavEnabled={navbar}/>
      <Home />
      <Faq />
      <Projects />
      <Testimonials />
      <Prices />
    </>
  )
}

export default App
