import '../App.css'
import { Cancel } from '../components/cancel'
import { Checkmark } from '../components/checkmark'

export const Prices = () => {

  return (
    <section className="prices">
      <header>Prices</header>
      <div className='prices-container'>
        <div className='price-card'>
          <p>Basic</p>
          <h4>For small beginner stores that are just getting started.</h4>
          <h1>$200</h1>

          <p className='feature'><Checkmark /> Customizable</p>
          <p className='feature'><Cancel /> Custom Design</p>
          <p className='feature'><Cancel /> Scalable React App</p>
          <p className='feature'><Checkmark /> Embeds</p>
          <p className='feature'><Cancel /> API Integration</p>
          <p className='feature-api'><Cancel /> Automatic product updates</p>
          <p className='feature-api'><Cancel /> Cart checkout</p>
          <p className='feature-api'><Cancel /> Webhooks</p>
          <p className='feature-api'><Cancel /> Display feedback</p>


        </div>

        <div className='price-card'>
          <p>Intermediate</p>
          <h4>For established stores expanding and elavated their business.</h4>
          <h1>$500</h1>

          <p className='feature'><Checkmark /> Customizable</p>
          <p className='feature'><Checkmark />Custom Design</p>
          <p className='feature'><Checkmark /> Scalable React App</p>
          <p className='feature'><Checkmark /> Embeds</p>
          <p className='feature'><Checkmark /> API Integration</p>
          <p className='feature-api'><Checkmark /> Automatic product updates</p>
          <p className='feature-api'><Cancel /> Cart checkout</p>
          <p className='feature-api'><Cancel /> Webhooks</p>
          <p className='feature-api'><Cancel /> Display feedback</p>
        </div>
        <div className='price-card'>
          <p>Professional</p>
          <h4>For professional stores that need the best and most advanced features and design.</h4>
          <h1>$1000</h1>

          <p className='feature'><Checkmark /> Customizable</p>
          <p className='feature'><Checkmark /> Custom Design</p>
          <p className='feature'><Checkmark /> Scalable React App</p>
          <p className='feature'><Checkmark /> Embeds</p>
          <p className='feature'><Checkmark /> API Integration</p>
          <p className='feature-api'><Checkmark /> Automatic product updates</p>
          <p className='feature-api'><Checkmark /> Cart checkout</p>
          <p className='feature-api'><Checkmark /> Webhooks</p>
          <p className='feature-api'><Checkmark /> Display feedback</p>
        </div>
      </div>
    </section>
  )
}

