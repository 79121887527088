import '../App.css'
import { useState } from 'react';
import PropTypes from 'prop-types';


export const Navbar = ( {isfixedNavEnabled} ) => {

    Navbar.propTypes = {
      isfixedNavEnabled: PropTypes.bool.isRequired,
    };


    const [active, setActive] = useState(false);
    const [openNav, setOpenNav] = useState(false);

    const copyText = (text) => {
        navigator.clipboard.writeText(text);

        setActive(true);

        setTimeout(() => {
            setActive(false)
        }, 700);
    }

    const goToSlide = (index) => {
      if(index === 1) {
        window.scrollTo({
          top: 1000,
          behavior: "smooth",
        });
      } else if(index === 2) {
        window.scrollTo({
          top: 2000,
          behavior: "smooth",
        });
      } else if(index === 3) {
        window.scrollTo({
          top: 2800,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 4000,
          behavior: "smooth",
        });
      }
    }


  return (
    <>
    <nav className={isfixedNavEnabled ? "nav-normal nav-normal-disabled" : "nav-normal"}>
          <div className="nav-author">
              <h1>BobbySmurf</h1>
              <h4>Fullstack Web Developer</h4>
          </div>
          <div className="nav-links">
              <h4 onClick={() => goToSlide(1)}>FAQ</h4>
              <h4 onClick={() => goToSlide(2)}>Past Projects</h4>
              <h4 onClick={() => goToSlide(3)}>Testimonials</h4>
              <h4 onClick={() => goToSlide(4)}>Pricing</h4>
          </div>
          <div className="nav-contact" onClick={() => copyText("bobbysmurf33")}>
            <div className={active ? "copy-alert copy-alert-active" : "copy-alert"}>Copied!</div>
            <h4><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><path fill="currentColor" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg> bobbysmurf33</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg>
          </div>
    </nav>

    <nav className={isfixedNavEnabled ? openNav ? "nav-fixed nav-fixed-open" : "nav-fixed" : "nav-fixed-disabled"}>
      <div className='nav-fixed-container'>
      <div className="nav-author">
              <h1>BobbySmurf</h1>
              <h4>Fullstack Web Developer</h4>
          </div>
          <div className="nav-links">
              <h4 onClick={() => goToSlide(1)}>FAQ</h4>
              <h4 onClick={() => goToSlide(2)}>Past Projects</h4>
              <h4 onClick={() => goToSlide(3)}>Testimonials</h4>
              <h4 onClick={() => goToSlide(4)}>Pricing</h4>
          </div>
          <div className="nav-contact" onClick={() => copyText("bobbysmurf33")}>
            <div className={active ? "copy-alert copy-alert-active" : "copy-alert"}>Copied!</div>
            <h4><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16"><path fill="currentColor" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/></svg> bobbysmurf33</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M14.82 4.26a10.14 10.14 0 0 0-.53 1.1a14.66 14.66 0 0 0-4.58 0a10.14 10.14 0 0 0-.53-1.1a16 16 0 0 0-4.13 1.3a17.33 17.33 0 0 0-3 11.59a16.6 16.6 0 0 0 5.07 2.59A12.89 12.89 0 0 0 8.23 18a9.65 9.65 0 0 1-1.71-.83a3.39 3.39 0 0 0 .42-.33a11.66 11.66 0 0 0 10.12 0q.21.18.42.33a10.84 10.84 0 0 1-1.71.84a12.41 12.41 0 0 0 1.08 1.78a16.44 16.44 0 0 0 5.06-2.59a17.22 17.22 0 0 0-3-11.59a16.09 16.09 0 0 0-4.09-1.35zM8.68 14.81a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.93 1.93 0 0 1 1.8 2a1.93 1.93 0 0 1-1.8 2zm6.64 0a1.94 1.94 0 0 1-1.8-2a1.93 1.93 0 0 1 1.8-2a1.92 1.92 0 0 1 1.8 2a1.92 1.92 0 0 1-1.8 2z" fill="currentColor"/></svg>
          </div>
      </div>
          <div onClick={() => setOpenNav(!openNav)} className='nav-fixed-arrow'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" ><path d="m19 13l-7-6l-7 6"/><path d="m19 17l-7-6l-7 6"/></g></svg>
          </div>
    </nav>

    
    </>
  )
}

export default Navbar;