import '../App.css'
import PropTypes from 'prop-types';
import { useState } from 'react';


export const Website = ({images, packageType}) => {

    const [activeIndex, setActiveIndex] = useState(0);

    Website.propTypes = {
    images: PropTypes.array.isRequired,
    packageType: PropTypes.number.isRequired,
    };

    console.log(activeIndex);

  return (
    <div className='website'>
        {packageType === 1 ? (
            <div className='package-basic'><p>Basic Package</p></div>
        ) : packageType === 2 ? (
            <div className='package-intermediate'><p>Intermediate Package</p></div>
        ) : (
            <div className='package-professional'><p>Professional Package</p></div>
        )
        }

        

        <div className='website-pictures'>
            {images.map((image, index) => {
                return <img src={image} key={image} className={activeIndex === index ? "image-active" : "image-inactive"}/>
            })}
        </div>
        <div className='website-nav'>
            {images.map((image, index) => {
                return <div onClick={() => setActiveIndex(index)}className={activeIndex === index ? "dot-active" : "dot"} key={image}></div>
            })}
        </div>
    </div>
  )
}

export default Website;